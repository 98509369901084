import React from "react"

import { getVariables, getexcerpt } from "../lib/utils"
import { useStaticQuery, graphql } from "gatsby"
import ItemPost from "./ItemPost"


const ListCertifications = ({ container = false, className = null, linkAll = false }) => {

    const certifications = useStaticQuery(graphql`
    query {
        allDatoCmsMattressCertification(
            filter: {locale: {eq: "en"}}
            sort: {fields: meta___createdAt, order: ASC}
        ) {
            nodes {
                id
                certificationName
                slug
                certificationDescription
                certificationLogoLarge {
                    gatsbyImageData(imgixParams: {h: "180", w: "180", fit: "scale"})
                }
                meta {
                    updatedAt
                }
            }
        }
    }
    `)

    const variables = getVariables()

    return (
        <div className={className}>
            <div className={` ${container ? 'container mx-auto' : null} `}>
                
                <div className="grid gap-6 grid-cols-1 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                    {certifications.allDatoCmsMattressCertification.nodes.map((certification) => {
                        const excerpt = getexcerpt(certification.certificationDescription, 20) + '...'
                        return (
                            <ItemPost
                                link={`/${variables.certification}/${certification.slug}`}
                                image={certification.certificationLogoLarge}
                                title={certification.certificationName}
                                excerpt={excerpt}
                                date={null}
                                btnLabel={variables.view_mattresses}
                                altStyle={true}
                                key={`key-list-review-${certification.id}`} />
                        )
                    }
                    )}
                </div>
            </div>
        </div>
    )
}

export default ListCertifications
