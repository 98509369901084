import React from "react"
import Layout from "../components/Layout"
import ListCertifications from "../components/ListCertifications"
import Seo from "../components/Seo/Seo"
import { useStaticQuery, graphql } from "gatsby"

// markup
const Certifications = () => {

  const data = useStaticQuery(graphql`
    query {
      datoCmsLocation(locale: {eq: "en"}, websiteUrl: {eq: "us.mattress.zone"}) {
        certificationsContentBeforeNode {
          childMarkdownRemark {
            html
          }
        }
        certificationsContentAfterNode {
          childMarkdownRemark {
            html
          }
        }
      }
    }
    `)

  return (
    <Layout>

      <Seo
        title={null}
        description={null}
        image={null}
        pathname={'/certifications'}
      />

      <main className="wrap-certifications bg-zone-1">
        <div className="container mx-auto px-4 xl:px-0">
          <div dangerouslySetInnerHTML={{ __html: data.datoCmsLocation.certificationsContentBeforeNode.childMarkdownRemark.html }} />
          <hr />
        </div>

        <ListCertifications className="bg-zone-1 py-12 px-4 xl:px-0" container={true} />

        <div className="bg-zone-1">
          <div className="container mx-auto px-4 xl:px-0">
            <div dangerouslySetInnerHTML={{ __html: data.datoCmsLocation.certificationsContentAfterNode.childMarkdownRemark.html }} />
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default Certifications
