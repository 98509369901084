import React from "react"
import { Link } from "gatsby"
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"

const ItemPost = ({ link = "/", image = null, title = "Lorem ipsum", excerpt = null, date = null, btnLabel = "View" }) => {
    let htmlImage = ''

    if (image) {
        const the_image = getImage(image)
        htmlImage = <Link to={link} className="w-full overflow-hidden rounded-2xl p-4">
            <GatsbyImage
                image={the_image}
                alt={image.alt ? image.alt : ""}
                className="block transform duration-300 hover:scale-125 wrap-contain"
            />
        </Link>
    } else {
        htmlImage = <Link to={link} className="w-full overflow-hidden rounded-2xl p-4">
            <StaticImage
                src="../images/test1.jpg"
                placeholder="blurred"
                layout="fullWidth"
                alt="MattressZone"
                className="block transform duration-300 hover:scale-125 wrap-contain"
            />
        </Link>
    }

    return (
        <article className="flex flex-wrap flex-col bg-zone-1 zone-shadow rounded-2xl">
            {htmlImage}
            <div className="flex flex-col justify-between p-4 flex-1">
                <h3 className="font-grotesk text-2xl mb-4 font-semibold text-zone-1">
                    <Link to={link} className="hover:underline">{title}</Link>
                </h3>
                {date ? <p className="text-zone-3">{new Date(date).toDateString()}</p> : null}
                {excerpt ? <div className="mb-4 text-base">{excerpt}</div> : null}
                <Link to={link} className="self-end py-2 px-4 rounded duration-300 bg-zone-11 text-white hover:bg-zone-9">{btnLabel}</Link>
            </div>
        </article>
    )
}

export default ItemPost
